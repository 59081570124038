<template>
  <q-btn round dense flat :ripple="false">
    <avatar size="24px">
      <span class="text-white">
        {{ firstName.substring(0, 1).toUpperCase() }}
      </span>
    </avatar>
    <div class="text-weight-medium text-capitalize q-ml-sm user-first-name">
      <b>{{ firstName }}</b>
      <q-icon name="expand_more" />
    </div>
    <q-menu class="q-mt-lg full-width" max-width="300px" :offset="[5, 10]">
      <div class="row no-wrap q-pa-md">
        <div class="column">
          <q-list>
            <div class="row">
              <q-item
                class="col-auto"
                clickable
                v-close-popup
                :to="{ name: 'account.personal-info' }"
              >
                <q-item-section avatar
                  ><q-icon size="1.5em" name="account_circle"
                /></q-item-section>
                <q-item-section
                  ><span class="text-weight-light relative-position">{{
                    $t("dashboard.card_user.my_account")
                  }}</span></q-item-section
                >
              </q-item>
              <q-item-section class="col">
                <q-btn
                  flat
                  @click="copyAction(user.parceiro)"
                  :disable="buttonCopyUserIdDisable"
                  class="bg-grey-2 q-pa-none text-weight-regular text-caption cursor-pointer btn-clipboard-user-id"
                >
                  <q-tooltip
                    transition-show="scale"
                    transition-hide="scale"
                    class="desktop-only"
                  >
                    {{
                      buttonCopyUserIdDisable ? "ID copiado" : "Copiar meu ID"
                    }}
                  </q-tooltip>
                  <i class="fas" :class="iconCopyUserId"></i>
                  <div class="q-ml-xs">
                    {{ $t("dashboard.card_user.id", { id: user.parceiro }) }}
                  </div>
                </q-btn>
              </q-item-section>
            </div>
            <q-item
              clickable
              v-close-popup
              :to="{ name: 'my-points.how-get' }"
              v-if="partner.id != 7112"
            >
              <q-item-section avatar
                ><q-icon size="1.5em" name="timeline"
              /></q-item-section>
              <q-item-section
                ><span class="text-weight-light relative-position">{{
                  $t("dashboard.card_user.how_to_earn")
                }}</span></q-item-section
              >
            </q-item>
            <q-separator />
            <q-item class="text-weight-bolder">
              <q-item-section avatar
                ><q-icon size="1.5em" name="monetization_on"
              /></q-item-section>
              <q-item-section
                ><q-item-label class="text-no-wrap q-pr-md"
                  ><span
                    class="relative-position"
                    v-html="
                      $t('dashboard.card_user.my_points', {
                        value: totalPoints || getPointsSummary.balance,
                      })
                    "
                  ></span></q-item-label
              ></q-item-section>
            </q-item>
            <div v-if="checkIfPartnerAllowsPWA && isMobile">
              <div
                v-if="
                  (isApple() &&
                    isSafari &&
                    !checkIfIsPWA &&
                    !pwaInstalledApple) ||
                  (pwaInstallationStatus && !checkIfIsPWA)
                "
              >
                <q-separator />
                <q-item clickable @click="installPWA">
                  <q-item-section avatar>
                    <q-icon size="1.5em" name="download" />
                  </q-item-section>
                  <q-item-section>
                    <span class="text-weight-light">
                      {{ $t("dashboard.card_user.install_pwa") }}
                    </span>
                  </q-item-section>
                </q-item>
              </div>
            </div>
            <q-separator />
            <q-item clickable v-close-popup @click="signOut">
              <q-item-section avatar>
                <q-icon size="1.5em" name="logout" />
              </q-item-section>
              <q-item-section>
                <span class="text-weight-light">
                  {{ $t("dashboard.card_user.exit") }}
                </span>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>
    </q-menu>
  </q-btn>
</template>

<script setup>
import {
  useStore,
  useActions,
  useGetters,
  createNamespacedHelpers,
} from "vuex-composition-helpers";
import Avatar from "@/modules/main/components/avatar";
import { checkIfPwaInstalledApple } from "../../../pwa/scripts/cookies";
import { notifyError, notifyInfo } from "@/shared/helpers/notify";
import { useQuasar, Platform } from "quasar";
import { copyToClipboard } from "quasar";
import { flipPWA } from "../../../pwa";
import { useRoute } from "vue-router";
import { computed, ref } from "vue";
import { onMounted, defineProps } from "vue";

defineProps({
  checkIfPartnerAllowsPWA: {
    type: String,
  },
  isMobile: {
    type: [Boolean, null],
  },
  checkIfIsPWA: {
    type: String,
  },
  isSafari: {
    type: Boolean,
    required: true,
  },
});

const route = useRoute();

const { useState } = createNamespacedHelpers("auth");

const { totalPoints, partner, getPointsSummary } = useGetters({
  totalPoints: "home/getRewardPoints",
  partner: "partner/getPartner",
  getPointsSummary: "home/getPointsSummary",
});

const { user } = useState(["user"]);

const { signOutAction, totalPointsAction, fetchDownloadApp } = useActions({
  signOutAction: "auth/signOutAction",
  totalPointsAction: "home/fetchRewardBalance",
  fetchDownloadApp: "pwa/fetchDownloadApp",
});

const $q = useQuasar();
const store = useStore();
const { pwa } = store.state;
const pwaActions = new flipPWA($q, store, Platform, fetchDownloadApp);
const pwaInstalledApple = checkIfPwaInstalledApple();

const iconCopyUserId = ref("fa-copy");
const buttonCopyUserIdDisable = ref(false);

const firstName = computed(() =>
  user.value ? user.value.nome.split(" ")[0] : "----"
);
const pwaInstallationStatus = computed(() => {
  return pwa.pwaPromptInstall ? pwa.pwaPromptInstall : null;
});

const isApple = () => pwaActions.isApple();

const signOut = () =>
  signOutAction().finally(() => {
    location.href = `/${partner.value.slug}/login`;
  });

const installPWA = () => pwaActions.install();

const copyAction = (text) => {
  copyToClipboard(text)
    .then(() => {
      iconCopyUserId.value = "fa-check";
      buttonCopyUserIdDisable.value = true;
      notifyInfo("global.copied.success");
    })
    .catch(() => {
      notifyError("global.copied.error");
    })
    .finally(() => {
      setTimeout(() => {
        iconCopyUserId.value = "fa-copy";
        buttonCopyUserIdDisable.value = false;
      }, 1500);
    });
};

onMounted(() => {
  if (route.name != "dashboard") totalPointsAction();
});
</script>
<style scoped>
.user-first-name {
  display: flex;
  align-items: center;
}

.btn-clipboard-user-id:hover {
  background: #e0dede !important;
}
</style>
