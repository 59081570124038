<template>
  <div
    class="q-pa-md"
    v-if="layoutConfig"
    :style="`background-color: ${layoutConfig.body_background}`"
  >
    <q-layout view="lHh Lpr lff">
      <q-header
        bordered
        :style="`background-color: ${layoutConfig.navbar_background}`"
      >
        <q-toolbar class="q-pr-lg row text-cs-black">
          <q-btn
            flat
            round
            dense
            icon="menu"
            class="mobile-only"
            @click="
              drawer = !drawer;
              miniState = false;
            "
          />
          <q-btn
            dense
            flat
            round
            size="12px"
            color="grey-10"
            @click="miniState = !miniState"
            :icon="miniState ? 'chevron_right' : 'chevron_left'"
            class="q-card--bordered mini-close-button-custom desktop-only bg-white"
          />
          <div class="row justify-center width-fit-content-flex">
            <q-item :to="{ name: 'dashboard' }">
              <q-img
                fit="contain"
                :width="$q.platform.is.mobile ? '60px' : '100px'"
                :src="logo"
              />
            </q-item>
          </div>
          <div class="q-gutter-sm row items-center no-wrap">
            <card-stores />
            <card-cluster v-if="false" />
            <card-notifications />
            <template v-if="isFlipnetCompany">
              <q-separator vertical />
              <listDashUnifiedGroups />
            </template>
            <template v-if="isFlipnetCompany || getSwitchCompanies()">
              <q-separator vertical />
              <list-my-companies />
            </template>

            <q-separator vertical />
            <card-user
              :checkIfIsPWA="checkIfIsPWA"
              :isMobile="isMobile"
              :isSafari="isSafari"
              :checkIfPartnerAllowsPWA="checkIfPartnerAllowsPWA"
            />
          </div>
        </q-toolbar>
      </q-header>
      <q-drawer
        bordered
        :width="200"
        show-if-above
        v-model="drawer"
        :mini="miniState"
        :breakpoint="400"
        :style="`background-color: ${layoutConfig.menu_itens_background}`"
      >
        <user-info
          :mini-state="miniState"
          style="z-index: 1"
          :style="`background-color: ${layoutConfig.menu_top_background}`"
        />
        <menu-list
          :mini-state="miniState"
          style="z-index: 2"
          :listColor="layoutConfig.menu_itens_text_color"
        />
      </q-drawer>
      <q-page-container>
        <q-page padding :style-fn="myTweak">
          <router-view></router-view>
        </q-page>
        <footer-component />
      </q-page-container>

      <div v-if="checkIfPartnerAllowsPWA && isMobile">
        <q-page-sticky
          v-if="
            (isApple() &&
              isSafari &&
              !checkIfIsPWA &&
              iconPlatform &&
              !checkStatusIconInLocalStorage &&
              !pwaInstalledApple) ||
            (pwaInstallationStatus &&
              !checkIfIsPWA &&
              iconPlatform &&
              !checkStatusIconInLocalStorage)
          "
          position="bottom-left"
          :offset="[18, 18]"
          id="floating-button-pwa"
        >
          <q-btn
            square
            padding="sm"
            fab
            class="flip-bg-secondary row items-center"
            @click="installPWA"
          >
            <img :src="iconPlatform" width="32px" alt="" />
            <b class="gt-sm text-white text-capitalize q-pl-xs"
              >Instalar aplicativo</b
            >
          </q-btn>
        </q-page-sticky>
      </div>
    </q-layout>
    <user-terms-check />
  </div>
</template>

<script setup>
import { getSwitchCompanies } from "@/shared/helpers/switchCompaniesHelper";
import {
  createNamespacedHelpers,
  useStore,
  useActions,
} from "vuex-composition-helpers";
import UserTermsCheck from "@/modules/main/views/userTermsCheck";
import FooterComponent from "../components/FooterComponent.vue";
import cardNotifications from "../views/card-notifications";
import CardCluster from "@/modules/main/views/card-cluster";
import CardStores from "@/modules/main/views/card-stores";
import UserInfo from "@/modules/main/views/user-info";
import ListMyCompanies from "../views/list-my-companies";
import listDashUnifiedGroups from "../views/listDashUnifiedGroups.vue";
import cardUser from "../views/card-user";
import menuList from "../views/menu-list";
import { computed, onMounted, ref } from "vue";
import helpers from "@/shared/helpers";

import { Platform, useQuasar } from "quasar";

import { flipPWA } from "../../../pwa";
import { checkIfPwaInstalledApple } from "../../../pwa/scripts/cookies";
import { checkPartnerSlugCanInstallPwa } from "../../../pwa/scripts/checkPartnerSlugCanInstallPwa";

import downloadIcon from "@/assets/icons/download.svg";
import androidIcon from "@/assets/icons/android.svg";
import windowsIcon from "@/assets/icons/windows.svg";
import appleIcon from "@/assets/icons/apple.svg";

const { getCustomLayout } = helpers.customLayout;

const partnerNamespace = createNamespacedHelpers("partner");

const { fetchDownloadApp } = useActions({
  fetchDownloadApp: "pwa/fetchDownloadApp",
});

const { logo, isFlipnetCompany } = partnerNamespace.useGetters({
  logo: "getPartnerParentLogo",
  isFlipnetCompany: "isFlipnetCompany",
});

const miniState = ref(true);
const drawer = ref(false);
const layoutConfig = ref(null);
const $q = useQuasar();
const store = useStore();
const { pwa } = store.state;
const pwaActions = new flipPWA($q, store, Platform, fetchDownloadApp);
const checkStatusIconInLocalStorage = flipPWA.checkStatusIconInLocalStorage();
const pwaInstalledApple = checkIfPwaInstalledApple();
const checkIfPartnerAllowsPWA = ref(null);
const isMobile = ref(Platform.is.mobile)
const isSafari = ref(Platform.is.safari)
const iconPlatform = ref(null);

document.body.classList.add("theme-light-dark");

const iconAccordingToPlatform = () => {
  if (Platform.is.win) {
    iconPlatform.value = windowsIcon;
  } else if (Platform.is.android) {
    iconPlatform.value = androidIcon;
  } else if (pwaActions.isApple()) {
    iconPlatform.value = appleIcon;
  } else {
    iconPlatform.value = downloadIcon;
  }

  return iconPlatform;
};


onMounted(() => {
  iconAccordingToPlatform();
  layoutConfig.value = getCustomLayout();
});

const isApple = () => pwaActions.isApple();

const installPWA = async () => {
  pwaActions.install();
};

checkPartnerSlugCanInstallPwa()
  .then((output) => {
    const { value } = output;

    if (value == "true") {
      checkIfPartnerAllowsPWA.value = true;
    }
  })
  .catch((error) => {
    console.log("checkPartnerSlugCanInstallPwa ERROR: ", error);
  });

const pwaInstallationStatus = computed(() => {
  return pwa.pwaPromptInstall ? pwa.pwaPromptInstall : null;
});

const checkIfIsPWA = computed(() => {
  return pwa.isPWA ? pwa.isPWA : null;
});
</script>

<style scoped>
.mini-close-button-custom {
  position: absolute;
  left: -15px;
}

.mini-close-button {
  top: 100px;
  right: -10px;
}

.fat {
  height: calc(100% - 150px);
  margin-top: 100px;
}

.q-drawer .q-ripple {
  display: none;
}

.q-btn {
  border-radius: 8px;
}

.has_scripts {
  margin-top: -20px !important;
}
</style>
